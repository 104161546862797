import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription, take } from 'rxjs';
import { Discipline } from 'src/app/models/discipline.model';
import { Document } from 'src/app/models/document.model';
import { ContentDataMap, DrawerContentType } from 'src/app/models/drawer-state.model';
import { ToasterType } from 'src/app/models/toaster.model';
import { UserSession } from 'src/app/models/user-session.model';
import { DrawerService } from 'src/app/services/drawer.service';
import { ToasterService } from 'src/app/services/toaster.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-user-session-form',
  templateUrl: './user-session-form.component.html',
  styleUrl: './user-session-form.component.scss'
})
export class UserSessionFormComponent implements OnInit {
  form: FormGroup;
  userSession: UserSession | undefined;
  drawerSubscription!: Subscription;
  submitted = false;
  submitting = false;
  userId: number | null = null;
  disciplines: Discipline[] = [];
  documents: Document[] = [];

  durations = [
    {id: 30, name: '30'},
    {id: 60, name: '60'},
    {id: 90, name: '90'},
    {id: 120, name: '120'}]

  constructor(
    private fb: FormBuilder,
    private drawerService: DrawerService,
    private userService: UserService,
    private toasterService: ToasterService,
  ) {
    this.form = this.fb.group({
      disciplineIds: [[], Validators.required],
      durationMinutes: [null, Validators.required],
      price: [null, Validators.required],
      includeGst: [false, Validators.required],
      chargeFees: [false, Validators.required],
      termsAndConditionsDocumentId: [null, Validators.required],
      instructions: ['']
    })
  }

  ngOnInit() {
    this.drawerSubscription = this.drawerService.drawerState$
      .pipe(take(1))
      .subscribe(state => {
        if (!state.contentData) return;
        if (state.activeContent === DrawerContentType.UserSession) {
          const contentData = state.contentData as ContentDataMap[DrawerContentType.UserSession];
          
          this.userId = contentData.userId ?? null;
          this.documents = contentData.documents ?? [];
          this.disciplines = contentData.disciplines ?? [];
  
          if (contentData.userSession) {
            this.userSession = contentData.userSession;
            this.form.get('disciplineIds')?.setValue(this.userSession?.disciplines.map(x => x.id));
            this.form.get('termsAndConditionsDocumentId')?.setValue(this.userSession?.termsAndConditionsDocument?.id);
            this.form.patchValue(this.userSession);
          }
        }
      });
  
    this.form.valueChanges.subscribe(() => {
      this.drawerService.markFormDirty(this.form.dirty);
    });
  }
  
  onSubmit() {
    this.submitted = true;
    this.submitting = true;
    if (this.form.invalid || this.userId == null) {
      this.submitting = false;
      return;
    }

    const formData: Partial<UserSession> = this.form.value;
    const request$ = this.userSession
    ? this.userService.updateUserSession(this.userId, this.userSession.id, formData)
    : this.userService.createUserSession(this.userId, formData);

    request$.subscribe({
      next: (response) => {
        this.drawerService.emitDrawerResult({id: response, ...formData});
        this.toasterService.addToaster({ type: ToasterType.Success, message: `Availability ${this.userSession ? 'updated' : 'created'}` });
        this.drawerService.markFormPristine();
        this.drawerService.closeDrawer();
      },
      error: (error) => {
        this.submitting = false;
        // TODO: pass validation error responses into the form as required
        console.log(error);
      }
    });
    this.submitting = false;
  }

  onCancel() {
    this.drawerService.closeDrawer()
  }

  ngOnDestroy() {
    this.drawerSubscription.unsubscribe();
  }

  onInitialValueSet() {
    this.form.markAsPristine();
    this.drawerService.markFormPristine();
  }
}
