<div>
    <app-back-button></app-back-button>
</div>
@if (loading) {
    <fc-loader/>
}
@else if (user) {
    <div class="mt-6 flex items-center gap-5">
        <div>
            <app-avatar 
                (click)="toggleValue === 'details' ? uploadImage() : null" 
                [class.cursor-pointer]="toggleValue === 'details'"
                variant="large" 
                [imageUrl]="user.imageUrl" 
                initials="{{user.firstName.charAt(0).toUpperCase()}}{{user.lastName.charAt(0).toUpperCase()}}">
            </app-avatar>
        </div>
        <div>
            <h1 class="text-3xl font-medium text-gray-900">{{user.firstName}} {{user.lastName}}</h1>
        </div> 
    </div>
    <div class="flex flex-col gap-6 mt-6">
        <div class="flex justify-between items-center">
            <fc-toggle-button-group [options]="toggleOptions" [(selectedValue)]="toggleValue" (selectedValueChange)="onToggleChange($event)"></fc-toggle-button-group>
        </div>
        @if (toggleValue === 'details') {
            <form [formGroup]="userForm" (ngSubmit)="onDetailsSubmit()">
                <div class="flex flex-col gap-4 w-full sm:w-1/2">
                    <fc-text-input formControlName="firstName" [required]="true"  label="First name" [required]="true" [submitted]="submitted"></fc-text-input>
                    <fc-text-input formControlName="lastName" [required]="true"  label="Last name" [required]="true" [submitted]="submitted"></fc-text-input>
                    <fc-text-input label="Email" [required]="true"  formControlName="email" [submitted]="submitted"><envelope-outline-icon #icon></envelope-outline-icon></fc-text-input>
                    <fc-text-input label="Mobile" formControlName="mobile" [submitted]="submitted" [errorMessages]="{pattern:'Please enter a valid mobile number.'}"><device-phone-mobile-outline-icon #icon></device-phone-mobile-outline-icon></fc-text-input>
                </div>
                <ng-container *ngIf="userForm.dirty">
                    <app-save-footer buttonText="Save Details" 
                        [error]="submitted && !userForm.valid"
                        [loading]="submitting"></app-save-footer>
                </ng-container>
            </form>
        }
        @else if (toggleValue === 'private-sessions') {
            <div class="border-t"></div>
            <div class="flex flex-col gap-4">
                <div class="flex flex-row justify-between">
                    <div class="text-lg font-semibold text-gray-900">
                        Sessions
                    </div>
                    <fc-button-secondary (click)="addSessionOption()">
                        <plus-small-outline-icon svgClass="heroicon-sw-1.5" [size]="20"/>
                        <span>Add option</span>
                    </fc-button-secondary>
                </div>
                @if (userSessions.length > 0) {
                    <app-user-sessions-grid 
                        [sessions]="userSessions"
                        (editClicked)="editSessionOption($event)"
                        (deleteClicked)="deleteSessionOption($event)"
                    />
                }
                @else {
                    <fc-empty-grid heading="No options found">
                        <p description>Add a new session option to get started.</p>
                    </fc-empty-grid>
                }

            </div>
            <div class="flex flex-col gap-4">
                <div class="flex flex-row justify-between">
                    <div class="text-lg font-semibold text-gray-900">
                        Availability
                    </div>
                    <fc-button-secondary (click)="addAvailability()">
                        <plus-small-outline-icon svgClass="heroicon-sw-1.5" [size]="20"/>
                        <span>Add availability</span>
                    </fc-button-secondary>
                </div>
                @if (userAvailability.length > 0) {
                    <app-user-availability-grid
                        [availability]="userAvailability"
                        (editClicked)="editAvailability($event)"
                        (deleteClicked)="deleteAvailability($event)"
                    />
                }
                @else {
                    <fc-empty-grid heading="No availability found">
                        <p description>Add a new availability to get started.</p>
                    </fc-empty-grid>
                }
            </div>
        }
    </div>

    @if (user.isActive) {
        <div class="flex justify-end align-end gap-3 top-0 right-4 sm:right-8 absolute pt-4 sm:pt-6">
            <fc-button-secondary (click)="archive()">
                <archive-box-outline-icon svgClass="heroicon-sw-1.5" [size]="20"/>
                <span>Archive user</span>
            </fc-button-secondary>
        </div>
    } 
    @else {
        <div class="flex justify-end align-end gap-3 top-0 right-4 sm:right-8 absolute pt-4 sm:pt-6">
            <fc-button-secondary (click)="archive()">
                <archive-box-outline-icon svgClass="heroicon-sw-1.5" [size]="20"/>
                <span>Restore user</span>
            </fc-button-secondary>
        </div>
    }
}

