@if(loading){
  <fc-loader/>
}
@else {
  <form [formGroup]="availabilityForm" (ngSubmit)="onSubmit()">
    <div class="mt-8 mb-16">
      <!-- Availabilities -->
      <div class="flex flex-col items-start gap-6 w-full" formArrayName="availabilities">
        <div
          *ngFor="let availability of availabilities.controls; let i = index"
          [formGroupName]="i"
          class="flex flex-wrap gap-x-20 gap-y-5 items-start justify-between border rounded-lg p-6 w-full gap"
        >
          <!-- Discipline, Days -->
          <div class="flex flex-col gap-5 lg:w-1/2 w-full flex-grow">
            <!-- Discipline Selection -->
            <fc-select-input
              label="Disciplines"
              [options]="disciplines"
              formControlName="disciplineIds"
              [submitted]="submitted"
              [required]="true"
              [multiple]="true"
              placeholder="Choose disciplines..."
            ></fc-select-input>

            <!-- Days Selection -->
            <fc-select-input
              label="Days"
              [options]="daysOfWeek"
              formControlName="days"
              [submitted]="submitted"
              [required]="true"
              [multiple]="true"
              placeholder="Choose days..."
            ></fc-select-input>
          </div>

          <!-- Time Ranges -->
            <div formArrayName="timeRanges" class="flex flex-col gap-5 justify-end">
              <!-- Time Range -->
              <div
                *ngFor="let range of getFormArrayControls(availability.get('timeRanges')); let j = index"
                [formGroupName]="j"
                class="flex flex-col"
              >
                <div class="flex justify-end gap-4">
                  <fc-time-input
                    label="Start Time"
                    formControlName="start"
                    [submitted]="submitted"
                    [required]="true"
                    [errorMessages]="{'required': 'Required.'}"
                  ></fc-time-input>
                  <fc-time-input
                    label="End Time"
                    formControlName="end"
                    [submitted]="submitted"
                    [required]="true"
                    [errorMessages]="{'required': 'Required.'}"
                  >              
                    <fc-button-secondary class=""
                      (click)="removeTimeRange(i, j)">
                      <trash-outline-icon [size]="16"></trash-outline-icon>
                    </fc-button-secondary>
                  </fc-time-input>
                </div>
                <!-- 
                  Show the error this way so we can have one error message for both fields. 
                  Maybe create a component to handle this in future if this pattern is common enough?
                  Or just highlight the problem fields and have a single error section that displays all messages?
                 -->
                <div *ngIf="submitted 
                              && !!range.get('start')?.value 
                              && !!range.get('end')?.value 
                              && (range.get('start')?.value ?? 0) >= (range.get('end')?.value ?? 1)">
                  <span class="block mt-2 text-sm text-error-500">Start Time must be before End Time</span>
                </div>
              </div>
              <!-- Add Time Slot -->
              <div class="flex justify-start">
                <fc-button-secondary class="min-w-[15rem]" (click)="addTimeRange(i)">
                  <plus-small-outline-icon svgClass="heroicon-sw-1.5" [size]="24"/>
                  Add time slot
                </fc-button-secondary>
              </div>
            </div>
          </div>
      </div>

      <!-- Add Availability Button -->
      <div class="mt-6 flex justify-center">
        <fc-button-secondary class="w-[15rem]" (click)="addAvailability()">
          <plus-small-outline-icon svgClass="heroicon-sw-1.5" [size]="24"/>
          Add Availability
        </fc-button-secondary>
      </div>
    </div>

    <!-- Submit Button -->
    <app-save-footer
      *ngIf="availabilityForm.dirty"
      [loading]="submitting"
      [buttonText]="'Save Availability'"
      [error]="submitted && !availabilityForm.valid"
    ></app-save-footer>
  </form>
}