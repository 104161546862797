import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, switchMap, tap } from 'rxjs';
import { User } from '../models/user.model';
import { AuthService } from '@auth0/auth0-angular';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Availability } from '../models/availability.model';
import { UserAvailability } from '../models/user-availability.model';
import { UserSession } from '../models/user-session.model';
import { UserBlockOut } from '../models/user-block-out.model';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  #userSubject = new BehaviorSubject<User | undefined>(undefined);

  constructor(private authService: AuthService, private http: HttpClient) { 
  }

  getCurrentUser() {
    if (this.#userSubject.value) {
      return this.#userSubject.asObservable();
    }

    return this.http.get<User>(`${environment.baseUrl}/user/me`).pipe(
      tap(user => this.#userSubject.next(user)),
      switchMap(() => this.#userSubject)
    )
  }

  logout() {
    this.authService.logout({
      logoutParams: { 
        returnTo: window.location.origin
      }
    });
    
    this.#userSubject.next(undefined);
  }

  me() {
    return this.#userSubject;
  }

  getUsers() {
    return this.http.get<User[]>(`${environment.baseUrl}/user`)
  }

  getUser(userId: number) {
    return this.http.get<User>(`${environment.baseUrl}/user/${userId}`)
  }

  createUser(user: Partial<User>) {
    return this.http.post<number>(`${environment.baseUrl}/user`, user)
  }

  updateUser(userId: number, user: any) {
    return this.http.put(`${environment.baseUrl}/user/${userId}`, user)
  }

  getAvailability(userId: number) {
    return this.http.get<Availability[]>(`${environment.baseUrl}/user/${userId}/availability`)
  }

  updateAvailability(userId: number, availabilities: any[]) {
    return this.http.put(`${environment.baseUrl}/user/${userId}/availability`, availabilities)
  }

  archiveUser(userId: number) {
    return this.http.post(`${environment.baseUrl}/user/${userId}/archive`, {})
  }

  restoreUser(userId: number) {
    return this.http.post(`${environment.baseUrl}/user/${userId}/restore`, {})
  }

  getUserSessions(userId: number): Observable<UserSession[]> {
    return this.http.get<UserSession[]>(`${environment.baseUrl}/user/${userId}/session`)
  }

  createUserSession(userId: number, userSession: Partial<UserSession>) {
    return this.http.post(`${environment.baseUrl}/user/${userId}/session`, userSession)
  }

  updateUserSession(userId: number, userSessionId: number, userAvailability: Partial<UserSession>) {
    return this.http.put(`${environment.baseUrl}/user/${userId}/session/${userSessionId}`, userAvailability)
  }

  deleteUserSession(userId: number, userSessionId: number) {
    return this.http.delete(`${environment.baseUrl}/user/${userId}/session/${userSessionId}`)
  }

  getUserAvailability(userId: number) {
    return this.http.get<UserAvailability[]>(`${environment.baseUrl}/user/${userId}/availability`)
  }

  createUserAvailability(userId: number, userAvailability: Partial<UserAvailability>) {
    return this.http.post(`${environment.baseUrl}/user/${userId}/availability`, userAvailability)
  }

  updateUserAvailability(userId: number, userAvailabilityId: number, userAvailability: Partial<UserAvailability>) {
    return this.http.put(`${environment.baseUrl}/user/${userId}/availability/${userAvailabilityId}`, userAvailability)
  }

  deleteUserAvailability(userId: number, userAvailabilityId: number) {
    return this.http.delete(`${environment.baseUrl}/user/${userId}/availability/${userAvailabilityId}`)
  }

  createUserBlockOut(userId: number, userBlockout: Partial<UserBlockOut>) {
    return this.http.post(`${environment.baseUrl}/user/${userId}/blockout`, userBlockout)
  }

  getUserBlockOut(userId: number, userBlockoutId: number) {
    return this.http.get<UserBlockOut>(`${environment.baseUrl}/user/${userId}/blockout/${userBlockoutId}`)
  }

  updateUserBlockOut(userId: number, userBlockoutId: number, userBlockout: Partial<UserBlockOut>) {
    return this.http.put(`${environment.baseUrl}/user/${userId}/blockout/${userBlockoutId}`, userBlockout)
  }

  deleteUserBlockOut(userId: number, userBlockoutId: number) {
    return this.http.delete(`${environment.baseUrl}/user/${userId}/blockout/${userBlockoutId}`)
  }

}
