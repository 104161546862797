@if (loading) {
    <fc-loader/>
} 
@else if (!billingCompleted) {
    <fc-empty-grid heading="Collect membership fees from students!">
        <banknotes-outline-icon icon #icon [size]="24"></banknotes-outline-icon>
        <p description>To get started, create/connect your <a href="https://stripe.com/" target="_blank" class="text-primary-600 hover:underline">Stripe</a> account.</p>
        <fc-button-primary variant="large" (click)="setupStripe()" [loading]="loadingStripe">Connect Stripe Account</fc-button-primary>
    </fc-empty-grid>
}
@else if (activeMemberships.length + archivedMemberships.length == 0) {
    <fc-empty-grid heading="No memberships found">
        <p description>Create a new membership to get started.</p>
        <fc-button-primary variant="large" (click)="addMembership()">
            <plus-small-outline-icon svgClass="heroicon-sw-1.5" [size]="24"></plus-small-outline-icon>
            <span>Add Membership</span>
        </fc-button-primary>
    </fc-empty-grid>
}
@else {
    <div class="flex justify-end align-end gap-3 top-0 right-4 sm:right-8 absolute pt-4 sm:pt-6" >
        <fc-button-primary (click)="addMembership()">
            <plus-small-outline-icon svgClass="heroicon-sw-1.5" [size]="24"></plus-small-outline-icon>
            <span class="hidden sm:flex">Add membership</span>
            <span class="flex sm:hidden">Add</span>
        </fc-button-primary>
    </div>

    @if (activeMemberships.length > 0 && archivedMemberships.length > 0) {
        <div class="flex justify-between items-center mt-6">
            <fc-toggle-button-group [options]="toggleOptions" [(selectedValue)]="toggleValue"/>
        </div>
    }

    @if (toggleValue == 'active' && activeMemberships.length > 0) {
        <div class="mt-6">
            <app-memberships-grid [memberships]="activeMemberships"/>
        </div>
    } @else if (toggleValue == 'archived' && archivedMemberships.length > 0) {
        <div class="mt-6">
            <app-memberships-grid [memberships]="archivedMemberships"/>
        </div>
    }
}
