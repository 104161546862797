@if (loading) {
<div class="relative h-full">
    <fc-loader/>
</div>
}
@else {
<form [formGroup]="form" class="h-full flex flex-col">
    <div tabindex="0" class="absolute opacity-0"></div> <!-- Prevents focus on inputs -->
    <div class="flex-1 flex flex-col justify-between overflow-y-auto pt-6">
        <div class="flex flex-col px-6 pb-6 gap-6">
            <div class="flex flex-row justify-between">
                <h1 class="select-none text-lg font-semibold text-gray-900">
                    {{ title }}
                </h1>
                <div class="flex flex-row gap-6">
                    @if(userBlockOut) {
                    <fc-button-secondary (click)="onRemove()">
                        <trash-outline-icon #icon />
                        <span>Remove</span>
                    </fc-button-secondary>
                    }
                    @else {
                    <div class="cursor-pointer text-gray-400"
                        (click)="onCancel()"><x-mark-outline-icon />
                    </div>
                    }

                </div>
            </div>
            
            <div class="flex flex-col gap-5 text-gray-900">
                <fc-select-input
                    label="User"
                    formControlName="userId"                    
                    placeholder="Choose trainer..."
                    [required]="true"
                    [submitted]="submitted"
                    [errorMessages]="{required:'Please select a trainer'}"
                    [options]="users"
                />

                <div class="flex flex-col gap-2">
                    <span class="text-sm text-gray-600">Private sessions cannot be scheduled in this time</span>
                    <div class="flex flex-col gap-5 border rounded-lg p-4">
                        <fc-time-input
                            label="Start time"
                            formControlName="startTime"
                            placeholder=""
                            [required]="true"
                            [submitted]="submitted"
                            inputClass="w-full"
                        />
                        <fc-time-input
                            label="End time"
                            formControlName="endTime"
                            placeholder=""
                            [required]="true"
                            [submitted]="submitted"
                            inputClass="w-full"
                        />
                    </div>
                </div>

                <div class="flex flex-col gap-2">
                    <span class="text-sm text-gray-600">Block-out time will apply to each date selected</span>
                    <div class="flex flex-col gap-5 border rounded-lg p-4">
                        <fc-date-input
                            label="Start date"
                            [required]="true"
                            placeholder=""
                            formControlName="startDate"
                            [submitted]="submitted"
                        />
                        <fc-date-input
                            label="End date"
                            [required]="true"
                            placeholder=""
                            formControlName="endDate"
                            [submitted]="submitted"
                        />
                    </div>
                </div>
            </div>

        </div>
        <div class="sticky bottom-0 border-t bg-white flex flex-row justify-between gap-3 px-6 py-4">
            <fc-button-secondary class="w-full" (click)="onCancel()">
                Cancel
            </fc-button-secondary>
            <fc-button-primary class="w-full" (click)="onSubmit()" [loading]="submitting">
                Confirm
            </fc-button-primary>
        </div>
    </div>
</form>
}
