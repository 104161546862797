import { Discipline } from "./discipline.model";
import { Document } from "./document.model";
import { UserBlockOut } from "./user-block-out.model";
import { UserSession } from "./user-session.model";
import { User } from "./user.model";

export interface DrawerState {
    isOpen: boolean;
    activeContent: DrawerContentType | null;
    contentData: ContentDataMap[DrawerContentType] | null;
    isDirty: boolean;
  }

export interface ContentDataMap {
    [DrawerContentType.UserSession]: {
        userSession?: UserSession | null;
        userId: number | undefined;
        documents: Document[];
        disciplines: Discipline[];
    },
    [DrawerContentType.UserBlockOut]: {
        userBlockOutId?: number | undefined;
        userId?: number | undefined | null;
        users?: Partial<User>[];
    }
}

export enum DrawerContentType {
    UserSession = 'userSession',
    UserBlockOut = 'userBlockOut'
}
