
@if (loading) {
    <fc-loader/>
}
@else {
    <div class="flex justify-end align-end gap-3 top-0 right-4 sm:right-8 absolute pt-4 sm:pt-6">
        <div (click)="archive()"
            class="h-[30px] sm:h-[38px] select-none hover:cursor-pointer text-gray-500 border border-gray-300 hover:bg-gray-50 focus:ring focus:ring-gray-100 focus:outline-0 font-medium rounded-lg text-sm pl-2 pr-4 py-2.5 flex items-center space-x-2">
            <archive-box-outline-icon svgClass="heroicon-sw-1.5" [size]="20"></archive-box-outline-icon>
            <span>
                {{student.isActive ? 'Archive' : 'Unarchive'}}
            </span>
        </div>
    </div>
    <div class="relative mb-4">
        <app-student-create-form [student]="student"></app-student-create-form>
    </div>
}