import { DatePipe } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Membership } from 'src/app/models/membership.model';

@Component({
  selector: 'app-student-membership-create-modal',
  templateUrl: './student-membership-create-modal.component.html',
  styleUrls: ['./student-membership-create-modal.component.scss']
})
export class StudentMembershipCreateModalComponent {
  selectedMembership: Membership | undefined;
  content: string | undefined;
  form: FormGroup;
  submitted = false;
  today = new Date(); 

  constructor(@Inject(MAT_DIALOG_DATA) public data: { memberships: Membership[] }, public dialogRef: MatDialogRef<StudentMembershipCreateModalComponent>, private fb: FormBuilder, private datePipe: DatePipe) { 
    this.form = this.fb.group({
      membership: [null, Validators.required],
      startDate: [this.datePipe.transform(new Date(), 'yyyy-MM-dd'), Validators.required]
    });

    if (data.memberships && data.memberships.length > 0) {
      this.form.get('membership')?.setValue(data.memberships[0]);
    }
  }

  dateChange(date: MatDatepickerInputEvent<Date>) {
    if (date?.value) {
      const timezoneOffsetMinutes = date.value.getTimezoneOffset();
      const adjustedDate = new Date(date.value.getTime() - (timezoneOffsetMinutes * 60 * 1000));
      const localISOString = adjustedDate.toISOString();
      this.form.get('startDate')?.setValue(localISOString.split('T')[0]);
    }
  }

  closeDialog(){
    this.dialogRef.close(null);
  }

  confirmDialog(){
    this.submitted = true;
    if (this.form.valid) {
      const formData = this.form.value;
      this.dialogRef.close(formData)
    }
  }
}
