@if (loading) {
    <fc-loader/>
}
@else if (studentNotes.length == 0) {
    <fc-empty-grid [heading]="manageNotesTitle">
        <p description>Click add note to get started.</p>
        <fc-button-primary variant="large" (click)="addNote()">
            <plus-small-outline-icon svgClass="heroicon-sw-1.5" [size]="24"></plus-small-outline-icon>
            <span>Add note</span>
        </fc-button-primary>
    </fc-empty-grid>
}
@else {
    <div class="flex justify-end align-end gap-3 top-0 right-4 sm:right-8 absolute pt-4 sm:pt-6">
        <fc-button-primary *ngIf="studentNotes.length > 0" (click)="addNote()">
            <plus-small-outline-icon svgClass="heroicon-sw-1.5" [size]="24"></plus-small-outline-icon>
            <span class="hidden sm:flex">Add note</span>
            <span class="flex sm:hidden">Add</span>
        </fc-button-primary>
    </div>
    <div class="mt-6">
        <app-student-notes-grid [notes]="studentNotes"/>
    </div>
}