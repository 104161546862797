import { Component } from '@angular/core';
import { BaseLayoutComponent } from '../base-layout/base-layout.component';
import { NavService } from 'src/app/services/nav.service';
import { WindowService } from 'src/app/services/window.service';

@Component({
  selector: 'app-calendar-layout',
  templateUrl: '../base-layout/base-layout.component.html',
})
export class CalendarLayoutComponent extends BaseLayoutComponent {
  constructor(windowService: WindowService, navService: NavService) {
    super(windowService, navService);
    this.paddingClasses = 'sm:pl-4 pt-2'; // smaller padding for calendar
  }
}
