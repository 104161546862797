@if (loading) {
    <fc-loader/>
}
@else if (disciplines.length > 0) {
    <div class="flex justify-end align-end gap-3 top-0 right-4 sm:right-8 absolute pt-4 sm:pt-6">
        <fc-button-primary (click)="addDiscipline()">
            <plus-small-outline-icon svgClass="heroicon-sw-1.5" [size]="24"></plus-small-outline-icon>
            <span>
                Add discipline
            </span>
        </fc-button-primary>
    </div>
    <div class="mt-6">
        <app-disciplines-grid [disciplines]="disciplines"></app-disciplines-grid>
    </div>
}
@else {
    <app-disciplines-empty (addDiscipline)="addDiscipline()"></app-disciplines-empty>
}
