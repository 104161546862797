<form [formGroup]="form" class="h-full flex flex-col">
    <div class="flex-1 flex flex-col justify-between overflow-y-auto pt-6">
        <div class="flex flex-col px-6 pb-6 gap-6">
            <div class="flex flex-row justify-between">
                <h1 class="select-none text-lg font-semibold text-gray-900">
                    {{userSession ? 'Edit' : 'Add'}} session option
                </h1>
                <div class="cursor-pointer text-gray-400"
                    (click)="onCancel()"><x-mark-outline-icon />
                </div>
            </div>
            
            <div class="flex flex-col gap-5 text-gray-900">
                <fc-select-input
                    label="Disciplines"
                    formControlName="disciplineIds"                    
                    placeholder="Choose disciplines..."
                    [multiple]="true"
                    [required]="true"
                    [submitted]="submitted"
                    [errorMessages]="{required:'Please select at least one discipline'}"
                    [options]="disciplines"
                />

                <fc-select-input
                    label="Session duration (mins)"
                    formControlName="durationMinutes"                    
                    placeholder="Select"
                    [required]="true"
                    [submitted]="submitted"
                    [errorMessages]="{required:'Please select a session duration'}"
                    [options]="durations"
                />

                <fc-currency-input
                    label="Price"
                    formControlName="price"
                    placeholder="00.00"
                    [required]="true"
                    [submitted]="submitted">

                </fc-currency-input>

                <fc-checkbox-input
                    label="Price is GST Inclusive"
                    formControlName="includeGst"
                />
                <div class="flex flex-col gap-0.5">
                    <fc-checkbox-input
                        label="Pass FightCloud processing fees to students"
                        formControlName="chargeFees"
                    />
                    <span class="ml-6 text-sm text-gray-600">If ticked, fees get added to amount student pays.</span>
                    <span class="ml-6 text-sm text-gray-600">If un-ticked, fees are taken after student pays.</span>
                </div>

                <fc-select-input
                    label="Terms & conditions document"
                    formControlName="termsAndConditionsDocumentId"                    
                    placeholder="Select..."
                    [required]="true"
                    [submitted]="submitted"
                    [errorMessages]="{required:'Please select a T&C document'}"
                    [options]="documents"
                />

                <div>
                    <fc-rich-text-editor
                        label="Instructions"
                        formControlName="instructions"
                        placeholder="Provide instructions to students for this session"
                        [toolbarOptions]="[[{'list': 'ordered'}, {'list': 'bullet'}]]"
                        (initialValueSet)="onInitialValueSet()"
                    />
                </div>
            </div>

        </div>
        @if(this.form.dirty) {
            <div class="sticky bottom-0 border-t bg-white flex flex-row justify-between gap-3 px-6 py-4">
                <fc-button-secondary class="w-full" (click)="onCancel()">
                    Cancel
                </fc-button-secondary>
                <fc-button-primary class="w-full" (click)="onSubmit()" [loading]="submitting">
                    Confirm
                </fc-button-primary>
            </div>
        }
    </div>
</form>
