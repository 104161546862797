<fc-grid [columns]="columns" [data]="availability" [actionTemplate]="actionTemplate">
</fc-grid>

<ng-template #actionTemplate let-row>
    <div class="flex justify-end gap-2">
        <fc-button-secondary (click)="onEdit(row)">
            Edit
        </fc-button-secondary>
        <fc-button-secondary (click)="onDelete(row)">
            Delete
        </fc-button-secondary>
    </div>
</ng-template>
