import { AfterViewInit, Component, OnInit, signal, ViewChild } from '@angular/core';
import { DatesSetArg, EventClickArg } from '@fullcalendar/core';
import { DateClickArg } from '@fullcalendar/interaction';
import { SessionService } from 'src/app/services/session.service';
import { BehaviorSubject, distinctUntilChanged, Observable, skip, Subject } from 'rxjs';
import { Router } from '@angular/router';
import { MatMenuTrigger } from '@angular/material/menu';
import { WindowService } from 'src/app/services/window.service';
import { NavService } from 'src/app/services/nav.service';
import { AlertService } from 'src/app/services/alert.service';
import { AlertNotification } from 'src/app/models/alert.model';
import { ToasterService } from 'src/app/services/toaster.service';
import { ToasterType } from 'src/app/models/toaster.model';
import { CalendarViewType } from '@fc-lib';
import { UserService } from 'src/app/services/user.service';
import { User } from 'src/app/models/user.model';
import { FormControl } from '@angular/forms';
import { DrawerService } from 'src/app/services/drawer.service';
import { ContentDataMap, DrawerContentType } from 'src/app/models/drawer-state.model';
import { UserBlockOut } from 'src/app/models/user-block-out.model';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements AfterViewInit, OnInit {
  resizeTrigger$ = new Subject<void>();
  viewChangeTrigger = signal(CalendarViewType.Week);

  isMobile = false;
  menuLeft = 0;
  menuTop = 0;
  createUrl = '';
  alerts: AlertNotification[] = [];
  events$ = new BehaviorSubject<any[]>([]);
  filterUsers: Partial<User>[] = [];
  users: Partial<User>[] = [];
  selectedFilterUserId = new FormControl<number>(0);
  private startDate = '';
  private endDate = '';

  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger | undefined;

  constructor(
    private sessionService: SessionService, 
    private router: Router, 
    private windowService: WindowService, 
    private navService: NavService, 
    private alertService: AlertService, 
    private toasterService: ToasterService,
    private userService: UserService,
    private drawerService: DrawerService,
  ) {}

  ngOnInit(): void {
    this.alertService.getActiveAlerts().subscribe(alerts => {
      this.alerts = alerts;
    });

    this.drawerService.drawerResult$.subscribe(() => {
      this.fetchCalendarEvents()
    });

    this.userService.getUsers().subscribe(users => {
      this.users = users.map(x => ({
        id: x.id, 
        name: `${x.firstName} ${x.lastName}`.trim()
      }));
      this.filterUsers = [
        { id: 0, name: 'All Users' },
        ...this.users
      ];
    });

    this.selectedFilterUserId.valueChanges.pipe(distinctUntilChanged()).subscribe(() => {
      this.fetchCalendarEvents();
    });
  }

  ngAfterViewInit(): void {
    this.windowService.isMobile.pipe(distinctUntilChanged()).subscribe(isMobile => {
      this.viewChangeTrigger.set(isMobile ? CalendarViewType.Day : CalendarViewType.Week);
    });

    this.navService.collapsed.pipe(distinctUntilChanged()).subscribe(() => {
      setTimeout(() => {
        this.resizeTrigger$.next();
      }, 50);
    });
  }

  handleDateSetChanged(event: DatesSetArg) {
    const startDate = event.startStr.split('T')[0];
    const endDate = event.endStr.split('T')[0];

    if (startDate === this.startDate && endDate === this.endDate) {
      return; // stops an extra fetch while calendar is initialising
    }

    this.startDate = startDate;
    this.endDate = endDate;

    this.fetchCalendarEvents();
  }

  fetchCalendarEvents() {
    if (!this.startDate || !this.endDate){
      return;
    }
    const userId = (this.selectedFilterUserId.value ?? 0) || undefined;
    this.sessionService.getCalendar(this.startDate, this.endDate, userId).subscribe((results) => {
      this.events$.next(this.mapEvents(results)
    )});
  }

  handleEventClick(event: EventClickArg) {
    if (event.event.extendedProps.isBlockOut) {
      this.editBlockout(+event.event.groupId);
    }
    else {
      this.router.navigate(['session', event.event.id]);
    }
  }

  handleDateClick(event: DateClickArg) {
    let events = this.events$.value;
    const eventDate = new Date(event.date.getTime());
    events = events.filter((x: any) => !x.isTemp);
    events.push({
      id: 0,
      title: 'New Session',
      start: event.dateStr,
      end: event.date.setHours(new Date(event.date.getTime()).getHours() + 1),
      backgroundColor: 'rgba(2,132,199, 0.5)',
      borderColor: '#0369a1',
      textColor: '#FFFFFF',
      isTemp: true
    });
    this.events$.next(events);

    const box = event.dayEl.getBoundingClientRect();
    const row = (event.jsEvent.target as HTMLElement)?.getBoundingClientRect();
    const isWeekend = event.date.getDay() === 0 || event.date.getDay() === 6
    this.menuLeft = this.isMobile ? 40 : isWeekend ? box.left - (132 + 5) : box.left + (box.width);
    this.menuTop = row.top ? row.top + window.scrollY : row.top + window.scrollY;

    const date = `${eventDate.getFullYear()}-${(eventDate.getMonth() + 1).toString().padStart(2, '0')}-${eventDate.getDate().toString().padStart(2, '0')}`;
    const hour = eventDate.getHours();
    const minute = eventDate.getMinutes();

    this.createUrl = `/session/create?date=${date}&hour=${hour}&minute=${minute}`;
    this.trigger?.openMenu();
  }

  menuStyle() {
    return { left: `${this.menuLeft}px`, top: `${this.menuTop}px`, position: 'absolute', display: 'block' };
  }

  create() {
    this.router.navigateByUrl(this.createUrl);
  }

  cancel() {
    let events = this.events$.value;
    events = events.filter((x: any) => !x.isTemp);
    this.events$.next(events);
  }

  onDismissAlert(id: number) {
    this.alertService.dismissAlert(id).subscribe(() => this.alerts = this.alerts.filter(x => x.id !== id));
  }

  onRemindAlert(id: number) {
    this.alertService.remindAlert(id).subscribe(() => {
      this.alerts = this.alerts.filter(x => x.id !== id);
      this.toasterService.addToaster({
        type: ToasterType.Success,
        message: "We'll remind you in one week."
      });
    });
  }

  handleCreateBlockout(){
    this.drawerService.openDrawer({
      activeContent: DrawerContentType.UserBlockOut, 
      contentData: {
        userId: this.selectedFilterUserId.value || undefined,
        users: this.users,
      }
    });
  }

  editBlockout(userBlockOutId: number){  
    this.drawerService.openDrawer({
      activeContent: DrawerContentType.UserBlockOut,
      contentData: {
        userBlockOutId: userBlockOutId,
        userId: this.selectedFilterUserId.value,
        users: this.users,
      }
    })
  }

  private mapEvents(events: any[]): any[] {
    const sessionStyles: Record<string, { background: string; border: string; text: string }> = {
      normal:             { background: '#3599E4', border: '#3599E4', text: '#FFFFFF' },
      private:            { background: '#B9D9C7', border: '#B9D9C7', text: '#000000' },
      privateCurrentUser: { background: '#258145', border: '#258145', text: '#FFFFFF' },
      blockOut:           { background: '#616161', border: '#616161', text: '#FFFFFF' },
    };
    return events.map(x => {
      const isDisabled = x.isPrivate && (x.checkInCount ?? -1 ) === 0;
      const isBlockOut = x.isBlockOut
      const isCurrentUserSession = false; // TODO: Implement check for current user
  
      let sessionType: 'normal' | 'private' | 'privateCurrentUser' | 'blockOut';
  
      if (x.isPrivate && isCurrentUserSession) {
        sessionType = 'privateCurrentUser';
      } else if (x.isPrivate) {
        sessionType = 'private';
      } else if (isBlockOut) {
        sessionType = 'blockOut';
      } else {
        sessionType = 'normal';
      }
  
      const { background, border, text } = sessionStyles[sessionType];
  
      return {
        id: x.id,
        groupId: x.groupId,
        title: x.title,
        start: x.date ? `${x.date} ${x.startTime}` : undefined,
        end: x.date ? `${x.date} ${x.endTime}` : undefined,
        startTime: x.startRecur ? x.startTime : undefined,
        endTime: x.endRecur ? x.endTime : undefined,
        startRecur: x.startRecur ? `${x.startRecur} ${x.startTime}` : undefined,
        endRecur: x.endRecur ? `${x.endRecur} ${x.endTime}` : undefined,
        backgroundColor: isDisabled ? '#F2F4F7' : background,
        borderColor: isDisabled ? '#D0D5DD' : border,
        textColor: isDisabled ? '#344054' : text,
        isDisabled,
        isBlockOut,
      };
    });
  }
  
}
