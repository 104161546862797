<div class="h-dvh flex flex-col bg-white">
    <div class="block sm:hidden">
        <app-mobile-navigation></app-mobile-navigation>
    </div>
    <div class="flex-grow flex flex-col">
        <app-side-drawer class="h-full">
            <div class="hidden sm:flex">
                <app-side-navigation *ngIf="!collapsed && !isMobile" (collapseClick)="collapse(true)"></app-side-navigation>
                <app-side-navigation-minimised *ngIf="collapsed || isMobile" (collapseClick)="collapse(false)"
                    [isMobile]="isMobile"></app-side-navigation-minimised>
            </div>

            <div [class]="paddingClasses" [ngClass]="marginClasses()" class="flex-grow h-full overflow-y-auto bg-white">
                <router-outlet></router-outlet>
            </div>
        </app-side-drawer>
    </div>
</div>