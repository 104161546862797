import { Component, OnInit } from '@angular/core';
import { NavService } from 'src/app/services/nav.service';
import { WindowService } from 'src/app/services/window.service';

@Component({
  selector: 'app-base-layout',
  templateUrl: './base-layout.component.html',
  styleUrl: './base-layout.component.scss'
})
export class BaseLayoutComponent implements OnInit {
  collapsed = false;
  isMobile = false;
  paddingClasses = 'px-4 sm:px-8 pt-4 pb-8'; // default padding (overridden in child classes)

  constructor(protected windowService: WindowService, protected navService: NavService) {}

  ngOnInit(): void {
    this.windowService.isMobile.subscribe(isMobile => this.isMobile = isMobile); 
    this.navService.collapsed.subscribe(collapsed => this.collapsed = collapsed);
  }

  collapse(value: boolean) {
    this.collapsed = value;
    this.navService.setCollapsed(value);
  }

  marginClasses() {
    return { 'ml-0': true, 'sm:ml-[14.5rem]': !this.collapsed && !this.isMobile, 'sm:ml-16': this.collapsed || this.isMobile }
  }
}
