import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { GridColumn } from '@fc-lib';
import { UserSession } from 'src/app/models/user-session.model';
import { CurrencyPipe } from '@angular/common';

@Component({
  selector: 'app-user-sessions-grid',
  templateUrl: './user-sessions-grid.component.html',
  styleUrl: './user-sessions-grid.component.scss'
})
export class UserSessionsGridComponent implements AfterViewInit {
  @Input() sessions: UserSession[] = [];
  @Output() editClicked = new EventEmitter<UserSession>();
  @Output() deleteClicked = new EventEmitter<number>();

  columns: GridColumn<UserSession>[] = [];

  constructor(private ref: ChangeDetectorRef, private currencyPipe: CurrencyPipe) {}

  ngAfterViewInit(): void {
    this.columns = [
      {
        header: 'Discipline',
        formatter: (row) => row.disciplines.map(x => x.name).join(', ')
      },
      {
        header: 'Session duration',
        formatter: (row) => `${row.durationMinutes} minutes`
      },
      {
        header: 'Price',
        formatter: (row) => this.currencyPipe.transform(row.price) ?? ''
      },
      {
        header: 'Terms & Conditions',
        formatter: (row) => row.termsAndConditionsDocument.name
      },
    ];
    this.ref.detectChanges();
  }

  onEdit(row: UserSession) {
    this.editClicked.emit(row);
  }

  onDelete(row: UserSession) {
    this.deleteClicked.emit(row.id);
  }
}
