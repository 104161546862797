import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmModalComponent } from '../components/modals/confirm-modal/confirm-modal.component';
import { ContentDataMap, DrawerContentType, DrawerState } from '../models/drawer-state.model';

@Injectable({
  providedIn: 'root'
})
export class DrawerService {
  private drawerState = new BehaviorSubject<DrawerState>({
    isOpen: false,
    activeContent: null,
    contentData: null,
    isDirty: false,
  });
  drawerState$ = this.drawerState.asObservable();

  private drawerResult = new Subject<any>()
  drawerResult$ = this.drawerResult.asObservable(); 

  constructor(private dialog: MatDialog) {}

  openDrawer(options: { activeContent: DrawerContentType | null; contentData?: ContentDataMap[DrawerContentType] }) {
    const currentState = this.drawerState.value;
  
    if (currentState.isOpen && currentState.isDirty) {
      this.confirmDiscardChanges().then(shouldSwitch => {
        if (shouldSwitch) {
          this.setDrawerState({
            isOpen: true,
            activeContent: options.activeContent,
            contentData: options.contentData,
            isDirty: false
          });
        }
      });
    } else {
      this.setDrawerState({
        isOpen: true,
        activeContent: options.activeContent,
        contentData: options.contentData,
        isDirty: false
      });
    }
  }
  

  closeDrawer(): Promise<boolean> {
    return new Promise(resolve => {
      const currentState = this.drawerState.value;
  
      if (currentState.isDirty) {
        this.confirmDiscardChanges().then(shouldClose => {
          if (shouldClose) {
            this.resetDrawerState();
            resolve(true);
          } else {
            resolve(false);
          }
        });
      } else {
        this.resetDrawerState();
        resolve(true);
      }
    });
  }

  markFormDirty(isDirty: boolean = true) {
    this.setDrawerState({ ...this.drawerState.value, isDirty: isDirty });
  }

  markFormPristine() {
    this.setDrawerState({ ...this.drawerState.value, isDirty: false });
  }

  confirmDiscardChanges(): Promise<boolean> {
    return new Promise(resolve => {
      const dialogRef = this.dialog.open(ConfirmModalComponent, {
        width: '400px',
        data: {
          title: "Unsaved Changes",
          content: "You have unsaved changes. Are you sure you want to leave?",
          yesText: "Discard",
          noText: "Cancel",
          yesColourRed: true
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        resolve(result);
      });
    });
  }

  resetDrawerState() {
    this.drawerState.next({
      isOpen: false,
      activeContent: null,
      contentData: null,
      isDirty: false
    })
  }

  emitDrawerResult(result: any) {
    this.drawerResult.next(result);
  }

  private setDrawerState(state: Partial<DrawerState>) {
    this.drawerState.next({
      ...this.drawerState.value,
      ...state,
    });
  }
}
