@if (loading) {
    <fc-loader/>
}
@else {

    @if (activeDocs.length > 0 && previousDocs.length > 0) {
        <div class="flex justify-between items-center mt-6">
            <fc-toggle-button-group [options]="toggleOptions" [(selectedValue)]="toggleValue"/>
        </div>
    }
    @if (toggleValue == 'active' && activeDocs.length > 0) {
        <div class="mt-6">
            <app-student-documents-grid [documents]="activeDocs" (exportDocument)="downloadStudentDocument($event)"/>
        </div>
    } @else if (toggleValue == 'historic' && historicDocs.length > 0) {
        <div class="mt-6">
            <app-student-documents-grid [documents]="historicDocs" (exportDocument)="downloadStudentDocument($event)"/>
        </div>
    }
}
