<form [formGroup]="form">
    <div class="flex flex-col p-6 gap-6">
        <div class="flex flex-row justify-between">
            <h1 class="select-none text-lg font-semibold text-gray-900">
                {{ userAvailability ? 'Edit' : 'Add' }} availability
            </h1>
            <div class="cursor-pointer text-gray-400"
                (click)="closeDialog()"><x-mark-outline-icon />
            </div>
        </div>
        
        <div class="flex flex-col gap-5 text-gray-900">
            <fc-select-input
                label="Days"
                formControlName="days"
                placeholder="Choose days..."
                [multiple]="true"
                [required]="true"
                [submitted]="submitted"
                [errorMessages]="{required:'Please select at least one day'}"
                [options]="dayOptions"
            />
            <fc-time-input
                label="Start time"
                formControlName="startTime"
                placeholder="Choose start time..."
                [required]="true"
                [submitted]="submitted"
                inputClass="w-full"
            />
            <fc-time-input
                label="End time"
                formControlName="endTime"
                placeholder="Choose end time..."
                [required]="true"
                [submitted]="submitted"
                inputClass="w-full"
            />
        </div>

        <div class="flex flex-row justify-between gap-3">
            <fc-button-secondary class="w-full" (click)="closeDialog()">
                Cancel
            </fc-button-secondary>
            <fc-button-primary class="w-full" (click)="confirmDialog()">
                Confirm
            </fc-button-primary>
        </div>
    </div>
</form>
