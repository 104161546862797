import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UserAvailability } from 'src/app/models/user-availability.model';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-user-availability-create-modal',
  templateUrl: './user-availability-create-modal.component.html',
  styleUrl: './user-availability-create-modal.component.scss'
})
export class UserAvailabilityCreateModalComponent {
  form: FormGroup;
  submitted = false;
  submitting = false;
  userId: number | null = null;
  userAvailability: UserAvailability | null = null;

  dayOptions = [
    { id: 1, name: 'Monday' },
    { id: 2, name: 'Tuesday' },
    { id: 3, name: 'Wednesday' },
    { id: 4, name: 'Thursday' },
    { id: 5, name: 'Friday' },
    { id: 6, name: 'Saturday' },
    { id: 0, name: 'Sunday' },
  ];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { userId: number, userAvailability: UserAvailability | null },
    public dialogRef: MatDialogRef<UserAvailabilityCreateModalComponent>, 
    private fb: FormBuilder, 
    private userService: UserService,
  ) { 
    this.userId = data.userId;
    this.userAvailability = data.userAvailability
    this.form = this.fb.group({
      days: [this.userAvailability?.days || [], Validators.required],
      startTime: [this.formatTime(this.userAvailability?.startTime) || '', Validators.required],
      endTime: [this.formatTime(this.userAvailability?.endTime) || '', Validators.required]
    });
    this.form.markAsPristine();
  }

  closeDialog(){
    this.dialogRef.close(null);
  }

  confirmDialog(){
    this.submitted = true;
    this.submitting = true;

    if (this.form.invalid || this.userId == null) {
      this.submitting = false;
      return;
    }

    const formData: Partial<UserAvailability> = this.form.value;
    const request$ = this.userAvailability
    ? this.userService.updateUserAvailability(this.userId, this.userAvailability.id, formData)
    : this.userService.createUserAvailability(this.userId, formData);

    request$.subscribe({
      next: (response) => {
        this.dialogRef.close({id: response, ...formData});
      },
      error: (error) => {
        this.submitting = false;
        // TODO: pass validation error responses into the form as required
        console.log(error);
      }
    });
    this.submitting = false;
  }

  private formatTime(time: string | undefined): string {
    return time ? time.substring(0, 5) : ''; // remove seconds
  }
}
