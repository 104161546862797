import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Subscription, take } from 'rxjs';
import { ContentDataMap, DrawerContentType } from 'src/app/models/drawer-state.model';
import { UserBlockOut } from 'src/app/models/user-block-out.model';
import { ToasterType } from 'src/app/models/toaster.model';
import { User } from 'src/app/models/user.model';
import { DrawerService } from 'src/app/services/drawer.service';
import { ToasterService } from 'src/app/services/toaster.service';
import { UserService } from 'src/app/services/user.service';
import { ConfirmModalComponent } from 'src/app/components/modals/confirm-modal/confirm-modal.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-user-block-out-form',
  templateUrl: './user-block-out-form.component.html',
  styleUrl: './user-block-out-form.component.scss'
})
export class UserBlockOutFormComponent implements OnInit {
  form: FormGroup;
  userBlockOut: UserBlockOut | undefined;
  drawerSubscription!: Subscription;
  loading = true;
  submitted = false;
  submitting = false;
  userId: number | null = null;
  users: Partial<User>[] = [];

  constructor(
    private fb: FormBuilder,
    private drawerService: DrawerService,
    private userService: UserService,
    private toasterService: ToasterService,
    private dialog: MatDialog,
  ) {
    this.form = this.fb.group({
      userId: [null, Validators.required],
      startDate: ['', Validators.required],
      endDate: ['', Validators.required],
      startTime: ['', Validators.required],
      endTime: ['', Validators.required],

    })
  }

  ngOnInit() {
    this.drawerSubscription = this.drawerService.drawerState$
      .pipe(take(1))
      .subscribe(state => {
        if (state.activeContent === DrawerContentType.UserBlockOut && state.contentData) {
          const contentData = state.contentData as ContentDataMap[DrawerContentType.UserBlockOut];
          this.userId = contentData.userId ?? null;
          this.users = contentData.users ?? [];
          this.form.get('userId')?.setValue(this.userId);

          if (contentData.userBlockOutId && this.userId) {
            this.userService.getUserBlockOut(this.userId, contentData.userBlockOutId).subscribe({
              next: (result) => {           
                this.userBlockOut = result;
                this.form.patchValue(this.userBlockOut);
                this.form.get('userId')?.disable();
                this.loading = false;
              },
              error: (error) => {
                this.toasterService.addToaster({ type: ToasterType.Error, message: `Error fetching blockout` });
                this.drawerService.closeDrawer();
              }
            });
          }
          else {
            this.loading = false;
          }
        }
      });

    this.form.valueChanges.subscribe(() => {
      this.drawerService.markFormDirty(this.form.dirty);
    });
  }

  onSubmit() {
    this.submitted = true;
    this.submitting = true;
    if (this.form.invalid || this.userId == null) {
      this.submitting = false;
      return;
    }

    const formData: Partial<UserBlockOut> = this.form.value;
    const request$ = this.userBlockOut
    ? this.userService.updateUserBlockOut(this.userId, this.userBlockOut.id, formData)
    : this.userService.createUserBlockOut(this.userId, formData);

    request$.subscribe({
      next: (response) => {
        this.drawerService.emitDrawerResult({id: response, ...formData});
        this.toasterService.addToaster({ type: ToasterType.Success, message: `Block-out ${this.userBlockOut ? 'updated' : 'created'}` });
        this.drawerService.markFormPristine();
        this.drawerService.closeDrawer();
      },
      error: (error) => {
        this.submitting = false;
        // TODO: pass validation error responses into the form as required
        console.log(error);
      }
    });
    this.submitting = false;
  }

  onRemove() {
    if (!this.userBlockOut || !this.userId) {
      return;
    }
    const dialogRef = this.dialog.open(ConfirmModalComponent, {
      width: '400px',
      data: {
        title: "Are you sure?",
        content: "Are you sure you want to remove this block-out? This action cannot be undone.",
        yesText: "Delete",
        noText: "Cancel",
        yesColourRed: true
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && this.userBlockOut && this.userId) {
        this.userService.deleteUserBlockOut(this.userId, this.userBlockOut.id).subscribe({
          next: (response) => {
            this.drawerService.emitDrawerResult({ response: response });
            this.toasterService.addToaster({ type: ToasterType.Success, message: 'Block-out deleted' });
            this.drawerService.markFormPristine();
            this.drawerService.closeDrawer();
          }
        })
      }
    });
  }

  onCancel() {
    this.drawerService.closeDrawer()
  }

  ngOnDestroy() {
    this.drawerSubscription.unsubscribe();
  }

  get title(){
    return `${this.userBlockOut ? 'Edit' : 'Add'} block-out`
  }

}
