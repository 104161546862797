<mat-drawer-container class="h-dvh bg-white overflow-hidden" [style.height]="heightStyle">
    <mat-drawer #drawer
        class="h-full bg-white min-w-[400px]" 
        mode="over" 
        position="end"
        [disableClose]="true"
        [opened]="(drawerState$ | async)?.isOpen">

        <div class="h-full flex flex-col bg-white">
            <ng-container *ngIf="(drawerState$ | async)?.activeContent === drawerContentType.UserSession">
                <app-user-session-form class="h-full"></app-user-session-form>
            </ng-container>
            <ng-container *ngIf="(drawerState$ | async)?.activeContent === drawerContentType.UserBlockOut">
                <app-user-block-out-form class="h-full"/>
            </ng-container>
        </div>

    </mat-drawer>

    <mat-drawer-content class="h-full">
        <ng-content></ng-content>
    </mat-drawer-content>
</mat-drawer-container>