import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import { ConfirmModalComponent } from 'src/app/components/modals/confirm-modal/confirm-modal.component';
import { UploadImageModalComponent } from 'src/app/components/modals/upload-image-modal/upload-image-modal.component';
import { Discipline } from 'src/app/models/discipline.model';
import { UserAvailability } from 'src/app/models/user-availability.model';
import { ToasterType } from 'src/app/models/toaster.model';
import { User } from 'src/app/models/user.model';
import { DisciplineService } from 'src/app/services/discipline.service';
import { ToasterService } from 'src/app/services/toaster.service';
import { UserService } from 'src/app/services/user.service';
import { environment } from 'src/environments/environment';
import { UserSession } from 'src/app/models/user-session.model';
import { UserAvailabilityCreateModalComponent } from 'src/app/components/modals/user-availability-create-modal/user-availability-create-modal.component';
import { DrawerService } from 'src/app/services/drawer.service';
import { DocumentService } from 'src/app/services/document.service';
import { Document, DocumentType } from 'src/app/models/document.model';
import { DrawerContentType } from 'src/app/models/drawer-state.model';

interface UserSessionAdditionalData {
  userId: number | undefined;
  documents: Document[];
  disciplines: Discipline[];
}

@Component({
  selector: 'app-settings-users-view',
  templateUrl: './settings-users-view.component.html',
  styleUrl: './settings-users-view.component.scss'
})
export class SettingsUsersViewComponent {
  loading: boolean = false;
  user: User | undefined;
  userId: number | undefined;
  userForm: FormGroup;
  userAvailability: UserAvailability[] = [];
  userSessions: UserSession[] = [];
  additionalData: UserSessionAdditionalData;
  submitted = false;
  submitting = false;

  toggleOptions = [
    { label: 'Details', value: 'details' },
    { label: 'Private sessions', value: 'private-sessions' },
  ];
  toggleValue = this.toggleOptions[0].value;

  constructor(
    private route: ActivatedRoute,
    private userService: UserService,
    private disciplineService: DisciplineService,
    private toasterService: ToasterService,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private drawerService: DrawerService,
    private documentService: DocumentService,
  ) {
    this.userForm = this.fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      mobile: ['', Validators.pattern(/^(\+\d{2} \d{3} \d{3} \d{3}|\d{10}|\d{4} \d{3} \d{3}|\+\d{11})$/)],
      imageUrl: ['']
    });
    this.additionalData = {
      userId: this.userId,
      documents: [],
      disciplines: []
    };
  }

  ngOnInit(): void {
    this.loadData();

    this.drawerService.drawerResult$.subscribe(() => {
      this.loadUserSessions()
    })
  }

  loadData(): void {
    const id = this.route.snapshot.paramMap.get('id');
  
    if (id) {
      this.userId = +id;
      this.additionalData.userId = this.userId;
      forkJoin({
        user: this.userService.getUser(this.userId),
        userAvailability: this.userService.getUserAvailability(this.userId),
        userSessions: this.userService.getUserSessions(this.userId),
        disciplines: this.disciplineService.getDisciplines(),
        documents: this.documentService.getDocuments(),
      }).subscribe({
        next: ({ user, userAvailability, userSessions, disciplines, documents }) => {
          this.user = user;
          this.userForm.patchValue(user);
          this.userAvailability = userAvailability;
          this.userSessions = userSessions;
          this.additionalData.disciplines = disciplines;
          this.additionalData.documents = documents.filter(x=> x.documentType == DocumentType.Student);
          this.loading = false;
        },
        error: () => {
          this.loading = false;
          this.toasterService.addToaster({ type: ToasterType.Error, message: 'Failed to load data' });
        }
      });
    }
  }

  loadUserSessions() {
    if (this.userId) {
      this.userService.getUserSessions(this.userId).subscribe({next: (result) => this.userSessions = result})
    }
  }

  onDetailsSubmit() {
    this.submitted = true;
    this.submitting = true;
    if (this.userForm.valid && this.userId) {
      const updatedUser = { ...this.user, ...this.userForm.value };
      this.userService.updateUser(this.userId, updatedUser).subscribe(() => {
        this.toasterService.addToaster({ type: ToasterType.Success, message: 'User details updated successfully' });
        this.userForm.markAsPristine();
        this.submitted = false;
      });
    }
    this.submitting = false;
  }

  onToggleChange(value: string): void {
    if (value !== 'details') {
      if (this.userForm.dirty) {
        this.userForm.reset(this.user);
      }
    }
  }

  archive() {
    if (this.userId) {
      const dialogRef = this.dialog.open(ConfirmModalComponent, {
        width: '400px',
        data: {
          title: "Are you sure?",
          content: this.user?.isActive ? "Are you sure you want to archive this user? The user will not be able to log into FightCloud." : "Are you sure you want to restore this user? The user will be able to log into FightCloud.",
          yesText: this.user?.isActive ? "Delete" : "Restore",
          noText: "Cancel",
          yesColourRed: this.user?.isActive
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          if (this.user?.isActive) {
            this.userService.archiveUser(this.userId!).subscribe({          
              next: () => {
                if (this.user) {
                  this.user.isActive = false;
                }
                this.toasterService.addToaster({ type: ToasterType.Success, message: 'User archived' });
              },
              error: () => {
                this.toasterService.addToaster({ type: ToasterType.Error, message: 'Failed to archive user' });
              }
            });
          } else {
            this.userService.restoreUser(this.userId!).subscribe({          
              next: () => {
                if (this.user) {
                  this.user.isActive = true;
                }
                this.toasterService.addToaster({ type: ToasterType.Success, message: 'User restored' });
              },
              error: () => {
                this.toasterService.addToaster({ type: ToasterType.Error, message: 'Failed to restore user' });
              }
            });
          }
        }
      });
    }
  }

  uploadImage() {
      const dialogRef = this.dialog.open(UploadImageModalComponent, { width: '400px' });
  
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.userForm.get('imageUrl')?.setValue(`${environment.blobBaseUrl}/avatar/${result}`);
          this.userForm.markAsDirty();
          if (this.user) {
            this.user.imageUrl = `${environment.blobBaseUrl}/avatar/${result}`;
          }
        }
      });
  }

  addSessionOption() {
    this.drawerService.openDrawer({activeContent: DrawerContentType.UserSession, contentData: this.additionalData});
  }

  editSessionOption(userSession: UserSession) {
    this.drawerService.openDrawer({activeContent: DrawerContentType.UserSession, contentData: {...this.additionalData, userSession}})
  }

  deleteSessionOption(sessionOptionId: number) {
    const dialogRef = this.dialog.open(ConfirmModalComponent, {
      data: { title: 'Are you sure?', content: 'Are you sure you want to delete this session option? This action cannot be undone.', noText: 'Cancel', yesText: 'Delete', yesColourRed: true },
      width: '400px'
    });

    dialogRef.afterClosed().subscribe({
      next: (result) => {
        if (result && this.userId) {
          this.userService.deleteUserSession(this.userId, sessionOptionId).subscribe({
            next: () => { 
              const index = this.userSessions.findIndex(a => a.id === sessionOptionId);
              if (index !== -1) {
                this.userSessions = [
                  ...this.userSessions.slice(0, index),
                  ...this.userSessions.slice(index + 1)
                ];
              }
              this.toasterService.addToaster({type: ToasterType.Success, message: 'Successfully deleted session option'});
            }
          });
        }
      }
    })
  }

  addAvailability() {
    const dialogRef = this.dialog.open(UserAvailabilityCreateModalComponent, {
        width: '400px',
        data: { userId: this.userId },
    });
    
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.userAvailability.push(result);
        this.toasterService.addToaster({ type: ToasterType.Success, message: 'Availability created' });
      }
    });
  }

  editAvailability(userAvailability: UserAvailability) {
    const dialogRef = this.dialog.open(UserAvailabilityCreateModalComponent, {
      width: '400px',
      data: { userId: this.userId, userAvailability: userAvailability },
    });
    
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        const index = this.userAvailability.findIndex(a => a.id === result.id);
        if (index !== -1) {
          this.userAvailability = [
            ...this.userAvailability.slice(0, index),
            result,
            ...this.userAvailability.slice(index + 1)
          ];
        }
        this.toasterService.addToaster({ type: ToasterType.Success, message: 'Availability updated' });
      }
    });
  }

  deleteAvailability(userAvailabilityId: number) {
    const dialogRef = this.dialog.open(ConfirmModalComponent, {
      data: { title: 'Are you sure?', content: 'Are you sure you want to delete this availability? This action cannot be undone.', noText: 'Cancel', yesText: 'Delete', yesColourRed: true },
      width: '400px'
    });

    dialogRef.afterClosed().subscribe({
      next: (result) => {
        if (result && this.userId) {
          this.userService.deleteUserAvailability(this.userId, userAvailabilityId).subscribe({
            next: () => { 
              const index = this.userAvailability.findIndex(a => a.id === userAvailabilityId);
              if (index !== -1) {
                this.userAvailability = [
                  ...this.userAvailability.slice(0, index),
                  ...this.userAvailability.slice(index + 1)
                ];
              }
              this.toasterService.addToaster({type: ToasterType.Success, message: 'Successfully deleted availability'});
            }
          });
        }
      }
    })
  }
}
