import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { GridColumn } from '@fc-lib';
import { UserAvailability } from 'src/app/models/user-availability.model';

@Component({
  selector: 'app-user-availability-grid',
  templateUrl: './user-availability-grid.component.html',
  styleUrl: './user-availability-grid.component.scss'
})
export class UserAvailabilityGridComponent  implements AfterViewInit {
  @Input() availability: UserAvailability[] = [];
  @Output() editClicked = new EventEmitter<UserAvailability>();
  @Output() deleteClicked = new EventEmitter<number>();

  daysOfWeek: Record<number, string> = {
    0: 'Sunday',
    1: 'Monday',
    2: 'Tuesday',
    3: 'Wednesday',
    4: 'Thursday',
    5: 'Friday',
    6: 'Saturday',
  };

  columns: GridColumn<UserAvailability>[] = [];

  constructor(private ref: ChangeDetectorRef) {}

  ngAfterViewInit(): void {
    this.columns = [
      {
        header: 'Days',
        formatter: (row) => row.days.map(id => this.daysOfWeek[id]).filter(Boolean).join(', ')
      },
      {
        header: 'Start time',
        formatter: (row) => this.formatTime(row.startTime),
      },
      {
        header: 'End time',
        formatter: (row) => this.formatTime(row.endTime),
      },
    ];
    this.ref.detectChanges();
  }

  onEdit(row: UserAvailability) {
    this.editClicked.emit(row);
  }

  onDelete(row: UserAvailability) {
    this.deleteClicked.emit(row.id);
  }

  formatTime(time: string): string {
    if (!time) return '';
  
    const [hours, minutes] = time.split(':').map(Number);
    const date = new Date();
    date.setHours(hours, minutes);
  
    return date.toLocaleTimeString('en-US', { hour: 'numeric', minute: '2-digit', hour12: true });
  }
}
