<alert-notification-bar [alerts]="alerts" (onDismiss)="onDismissAlert($event)" (onRemind)="onRemindAlert($event)"></alert-notification-bar>
<fc-calendar
  [events$]="events$"
  (dateClicked)="handleDateClick($event)"
  (eventClicked)="handleEventClick($event)"
  (dateSetChanged)="handleDateSetChanged($event)"
  [viewChangeTrigger]="viewChangeTrigger"
  [resizeTrigger$]="resizeTrigger$">

  <!-- Projected Header buttons-->
  <div fc-header-buttons class="flex flex-row gap-3 mr-4" *ngIf="filterUsers.length > 0">
    <fc-button-secondary (click)="handleCreateBlockout()">
      <calendar-days-outline-icon #icon/>
      <span class="whitespace-nowrap hidden sm:block">Add block-out</span>
      <span class="whitespace-nowrap block sm:hidden">Add</span>
    </fc-button-secondary>
    <div class="min-w-40 max-h-[2.375rem]">
      <fc-select-input 
        [options]="filterUsers"
        placeholder=""
        [formControl]="selectedFilterUserId"
        [firstOptionDefault]="true"
        variant="button"
      />
    </div>
  </div>

  <!-- Projected Mat Menu -->
  <div fc-calendar-menu>
    <button class="invisible" mat-button [matMenuTriggerFor]="menu" [style]="menuStyle()" (menuClosed)="cancel()">Menu</button>
    <mat-menu #menu="matMenu">
      <div class="flex justify-end px-2">
        <button type="button" (click)="create()" class="text-white border border-blue-600 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-3 py-1.5 text-center mr-2">
          Create
        </button>
        <button type="button" (click)="cancel()" class="focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm px-2 py-2 text-center text-gray-500 hover:text-white border border-gray-500 hover:bg-gray-600">
          <trash-outline-icon class="cursor-pointer h-5 w-5" aria-hidden="true"></trash-outline-icon>
        </button>
      </div>
    </mat-menu>
  </div>

</fc-calendar>