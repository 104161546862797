@if (loading) {
    <fc-loader/>
} 
@else if (activeUsers.length == 0 && archivedUsers.length == 0) {
    <fc-empty-grid
        heading="No Users Found">
        <p description>Contact FightCloud support.</p>
    </fc-empty-grid>
}
@else {
    <div class="flex justify-end align-end gap-3 top-0 right-4 sm:right-8 absolute pt-4 sm:pt-6">
        <fc-button-primary (click)="addUser()">
            <plus-small-outline-icon svgClass="heroicon-sw-1.5" [size]="24"></plus-small-outline-icon>
            <span class="hidden sm:flex">Add user</span>
            <span class="flex sm:hidden">Add</span>
        </fc-button-primary>
    </div>

    @if (activeUsers.length > 0 && archivedUsers.length > 0) {
        <div class="flex justify-between items-center mt-6">
            <fc-toggle-button-group [options]="toggleOptions" [(selectedValue)]="toggleValue"/>
        </div>
    }

    @if (toggleValue == 'active' && activeUsers.length > 0) {
        <div class="mt-6">
            <app-users-grid [users]="activeUsers"/>
        </div>
    } @else if (toggleValue == 'archived' && archivedUsers.length > 0) {
        <div class="mt-6">
            <app-users-grid [users]="archivedUsers"/>
        </div>
    }
}