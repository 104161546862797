import { Component, OnInit } from '@angular/core';
import { DrawerService } from 'src/app/services/drawer.service';
import { DrawerContentType } from 'src/app/models/drawer-state.model';
import { WindowService } from 'src/app/services/window.service';

@Component({
  selector: 'app-side-drawer',
  templateUrl: './side-drawer.component.html',
  styleUrl: './side-drawer.component.scss'
})
export class SideDrawerComponent implements OnInit {
  drawerState$ = this.drawerService.drawerState$;
  drawerContentType = DrawerContentType;
  isMobile = false;
  
  constructor(
    private drawerService: DrawerService,
    private windowService: WindowService,
  ) {}

  ngOnInit(): void {
    this.windowService.isMobile.subscribe(isMobile => {
      this.isMobile = isMobile;
    })
  }

  get heightStyle() {
    return this.isMobile ? 'calc(100dvh - 48px)' : '100dvh';
  }
}
